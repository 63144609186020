import BaseSelect from '@/components/common/base-select/base-select'
import { validatorRequiredTerm } from '@/utils/validate'
import { getDictLists } from '@/filters/fromDict'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import termOfValidity from '@/components/packages/term-of-validity/term-of-validity.vue'
import fileUpdate from '../components/file-update'
export const fromConfig = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: false, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商名称',
      prop: 'gysId',
      tag: BaseSelect,
      attrs: {
        filterable: true,
        placeholder: '请选择',
        options: content.gysData,
        selectedField: ['keyId', 'enterpriseName']
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('REVIEW_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    },

    {
      label: '文件制作状态',
      prop: 'fileMakeStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('FILE_MAKE_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    }
  ]
}
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商名称',
      prop: 'gysCompanyName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '融资金额(元)',
      prop: 'applyAmount',
      'show-overflow-tooltip': true,
      formatter: (row) => {
        return row.applyAmount
          ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
          : ''
      },
      align: 'right'
    },
    {
      label: '审核状态',
      prop: 'reviewStatusName',
      'show-overflow-tooltip': true,
      formatter: row => row.reviewStatusName ? row.reviewStatusName : '--'
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeName',
      'show-overflow-tooltip': true
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
// 评审会批复制作
// 会议基本信息
export const financingConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '会议批次',
      prop: 'meetingBatchNo',
      rules: [{ required: false, message: '请输入会议批次', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols
    },

    {
      label: '评审会时间',
      prop: 'meetingDate',
      tag: 'el-date-picker',
      rules: [{ required: false, message: '请选择评审会时间', trigger: 'change' }],
      attrs: {
        type: 'datetime',
        placeholder: '请选择',
        disabled: true
      },
      cols
    },
    {
      label: '业务经理',
      prop: 'busiManagerName',
      rules: [{ required: false, message: '请输入业务经理', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        disabled: true
      },
      cols
    },
    {
      label: '业务分管领导',
      prop: 'businessLeaderName',
      rules: [
        { required: false, message: '请输入业务分管领导', trigger: 'change' }
      ],
      attrs: {
        placeholder: '请选择',
        disabled: true
      },
      cols
    },
    {
      label: '风控经理',
      prop: 'riskManagerName',
      rules: [{ required: false, message: '请输入风控经理', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        disabled: true
      },

      cols
    }
  ]
}
// 最终方案
export const programmeConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }

  return [
    {
      label: '供应商名称',
      prop: 'gysName',
      rules: [{ required: false, message: '请输入会议类型', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        disabled: true
      },
      cols
    },
    // {
    //   label: '授信总额(元)',
    //   prop: 'givingCreditAmountTotal',
    //   rules: [{ required: false, message: '请输入授信总额', trigger: 'blur' }],
    //   attrs: {
    //     placeholder: '请输入',
    //     maxlength: 50,
    //     type: 'Y',
    //     disabled: true
    //   },
    //   tag: AmountInput,
    //   cols
    // },
    // {
    //   label: '存量稳享付余额(元)',
    //   prop: 'stockWxfBalance',
    //   rules: [{ required: true, message: '请输入存量稳享付余额', trigger: 'blur' }],
    //   attrs: {
    //     placeholder: '请输入',
    //     maxlength: 50,
    //     type: 'Y'
    //   },
    //   tag: AmountInput,
    //   on: {
    //     blur (data) {
    //       content.changeGivingCreditAmountTotal()
    //     }
    //   },
    //   cols
    // },
    // {
    //   label: '存量稳时付余额(元)',
    //   prop: 'stockWsfBalance',
    //   rules: [{ required: true, message: '请输入存量稳时付余额', trigger: 'blur' }],
    //   attrs: {
    //     placeholder: '请输入',
    //     maxlength: 50,
    //     type: 'Y'
    //   },
    //   on: {
    //     blur (data) {
    //       content.changeGivingCreditAmountTotal()
    //     }
    //   },
    //   tag: AmountInput,
    //   cols
    // },
    // {
    //   label: '存量稳保付余额(元)',
    //   prop: 'stockWbfBalance',
    //   rules: [{ required: true, message: '请输入存量稳保付余额', trigger: 'blur' }],
    //   attrs: {
    //     placeholder: '请输入',
    //     maxlength: 50,
    //     type: 'Y'
    //   },
    //   tag: AmountInput,
    //   on: {
    //     blur (data) {
    //       content.changeGivingCreditAmountTotal()
    //     }
    //   },
    //   cols
    // },
    // TODO
    {
      label: '本次额度(元)',
      prop: 'stockWxfBalanceInThisCase',
      rules: [{ required: true, message: '请输入本次稳享付额度', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        type: 'Y'
      },
      on: {
        blur (data) {
          content.changeGivingCreditAmountTotal()
        }
      },
      tag: AmountInput,
      cols
    },
    {
      label: '融资期限',
      prop: 'financeTerm',
      tag: termOfValidity,
      rules: [{ required: true, validator: validatorRequiredTerm(content.formData.financeTermUnit), trigger: ['change', 'blur'] }],
      attrs: {
        dictType: 'MM',
        placeholder: '请选择',
        maxlength: 5,
        type: 'ZS',
        validTimeUnit: content.formData.financeTermUnit
      },
      on: {
        update (val) {
          console.log(val, 111)
          content.changeValidTimeUnit(val)
        }
      },
      cols
    },
    {
      label: '综合融资利率(%)',
      prop: 'financeRate',
      rules: [{ required: true, message: '请输入融资利率', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        type: 'BL'
      },
      tag: AmountInput,
      cols
    },

    {
      label: '服务费率(%)',
      prop: 'serviceRate',
      rules: [{ required: true, message: '请输入融资利率', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        type: 'BL'
      },
      tag: AmountInput,
      cols
    },

    {
      label: '资方成本价格(%)',
      prop: 'financeInterestRate',
      rules: [{ required: true, message: '请输入资方成本价格', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        type: 'BL',
        disabled: content.financeInterestRateType
      },
      tag: AmountInput,
      cols
    },
    {
      label: '资金方',
      prop: 'capitalSideId',
      rules: [{ required: true, message: '请选择资金方', trigger: 'change' }],
      attrs: {
        filterable: true,
        placeholder: '请选择',
        options: content.providertData,
        selectedField: ['keyId', 'capitalAndProductName']
      },
      tag: BaseSelect,
      on: {
        change (data) {
          content.changeCapitalSideId(data)
        }
      },
      cols
    },

    {
      label: '还款方式',
      prop: 'repayTypeCode',
      rules: [{ required: true, message: '请选择还款方式', trigger: ['blur', 'change'] }],
      attrs: {

        placeholder: '请选择',
        options: getDictLists('REPAY_TYPE_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      cols
    },
    {
      label: '放款条件',
      prop: 'termsOfLoan',
      attrs: {
        placeholder: '补充最新征信/补充近一个月工商备案章程等',
        maxlength: 50
      },
      rules: [
        {
          required: true,
          message: '请输入放款条件',
          trigger: ['change', 'blur']
        }
      ],
      cols: { xs: 24, sm: 24, md: 16, lg: 10, xl: 12 }
    },
    {
      label: '担保措施',
      prop: 'guaranteeCondition',
      attrs: {
        placeholder: '示例：《金地集团华中区域XXXXXX项目XXXXXX工程》，合同编号XX-XX-XXXX-XX-001，合同编号HZ-CS-LGXS-SG-25的竣工/结算款做质押/转让；',
        type: 'textarea',
        rows: 4,

        maxlength: 1000,
        'show-word-limit': true
      },
      rules: [
        {
          required: true,
          message: '请输入担保措施',
          trigger: ['change', 'blur']
        }
      ],
      span: 24
    },
    {
      label: '反担保措施',
      prop: 'againstGuaranteeCondition',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 4,

        maxlength: 1000,
        'show-word-limit': true
      },
      rules: [
        {
          required: true,
          message: '请输入担保措施',
          trigger: ['change', 'blur']
        }
      ],
      span: 24
    },
    {
      label: '贷后要求',
      prop: 'requestWithLoan',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 4,
        maxlength: 1000,
        'show-word-limit': true
      },
      rules: [
        {
          required: true,
          message: '请输入贷后要求',
          trigger: ['change', 'blur']
        }
      ],
      span: 24
    },
    {
      label: '其他',
      prop: 'other',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 4,
        maxlength: 1000,
        'show-word-limit': true
      },
      rules: [
        {
          required: true,
          message: '请输入',
          trigger: ['change', 'blur']
        }
      ],
      span: 24
    }
  ]
}
// 文件生成与下载
export const fileConfig = (content) => {
  const span = 12
  return [
    {
      label: '文件名称',
      prop: 'fileName',
      attrs: {
        placeholder: '请生成或上传文件',
        generateFileId: content.formData.fileListInfo.fileId
      },
      span,
      tag: fileUpdate,
      rules: [
        { required: true, message: '请生成或上传文件', trigger: 'change' }
      ],
      on: {
        generate: (data) => {
          content.send('generate')
        },
        upload: (data) => {
          content.formData.fileListInfo.fileId = data.fileId
        },
        preview: (data) => {
          content.previewShow()
        },
        delFile: () => {
          content.delFile()
        }
      }
    }
  ]
}
// 评审会问题
export const problemConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '问题描述',
      prop: 'description',
      minWidth: '150px',
      HiddenOverflow: true
    },
    {
      label: '落实情况',
      prop: 'situation',
      minWidth: '150px',
      HiddenOverflow: true
    },

    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
// 风控经理信息补充
export const measuresConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '风控经理补充信息',
      prop: 'content',
      minWidth: '150px',
      HiddenOverflow: true
    },

    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
// 节点回退
export const nodForm = (content) => {
  return [
    {
      label: '选择退回节点',
      prop: 'repayTypeCode',
      rules: [{ required: true, message: '请选择选择退回节点', trigger: 'change' }],
      attrs: {

        placeholder: '请选择',
        options: getDictLists('REPAY_TYPE_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      span: 24
    }
  ]
}
